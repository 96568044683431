<template>
  <div class="chat-input" @keydown.esc="showSearchModal = false">
    <div class="input-row">
      <textarea ref="chatTextArea" rows="1" :disabled="inputLocked" placeholder="input your prompt here" @input="checkForNewLine"
        @keydown.enter.exact.prevent="sendMessage()"></textarea>
      <button @click="sendMessage" :disabled="inputLocked" class="btn btn-primary">Send</button>
    </div>
    <div class="status-row">
      {{ status }}
      <span v-if="inputLocked">
        <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
      </span>
      <div v-if="!inputLocked" class="usage">
          Cost estimation: <span class="cost-estimation"> {{ "$" + (typeof costEstimation === 'number' ? costEstimation.toFixed(3) : costEstimation) }}</span>
          / <span class="credit-balance"> {{ "$" + (typeof balance === 'number' ? balance.toFixed(2) : balance) }}</span>
      </div>
    </div>
    <div class="control-row">
      <div class="settings">
        <div class="setting-label">Context:</div>
        <select class="send-history-dropdow form-control" v-model="contextInclude">
          <option value="manual" selected>
            manual
          </option>
          <option value="none">
            none
          </option>
          <option value="all">
            all
          </option>
        </select>
        <div class="setting-label">Model:</div>
        <select class="model-dropdown form-control" v-model="aiModel">
          <option v-if="enableFakeModel" value="fake" selected>
            Fake
          </option>
          <option :value="HAIKU_MODEL">
            Haiku
          </option>                     
          <option :value="SONNET_MODEL" selected>
            Sonnet 3.5
          </option>                   
          <option :value="GPT_MODEL">
            GPT-4
          </option>
          <option :value="CODE_ONLY_MODEL">
            Code Only
          </option>
          <!-- <option :value="GOOGLE_WEB_MODEL" selected>
            Google
          </option> -->
        </select>
        <button @click="doWebSearch" class="btn btn-primary google-btn">Web Search</button>
        <button @click="showSearchModal = true" class="btn btn-primary history-btn">History</button>
        <button @click="clearChatLogs" class="btn btn-danger">Clear</button>
        <SearchModal :showModal="showSearchModal" @close-search-modal="showSearchModal = false" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.chat-input {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  box-sizing: border-box;
}

.input-row {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 8px;
  width: 100%;
}

.input-row button {
  margin-left: 10px;
}

textarea {
  resize: none;
  overflow: hidden;
  padding: 1ch;
  flex-grow: 2;
}

.status-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  background-color: #757575;
  color: white;
  height: 1.5em;
}

.control-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 100%;
}

.settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  min-width: 100%;
}

.send-history-dropdow,
.model-dropdown {
  margin-right: 2ch;
  font-size: 12px;
  flex: 1 1 auto;
  max-width: 8em;
}

.history-btn {
  margin-left: 1em;
  margin-right: 1em;
}

.usage {
  margin-left: auto;
  margin-right: 1em;
  font-size: 12px;
}

button {
  cursor: pointer;
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }
</style>

<script>
import { EventBus } from '@/utils/EventBus';
import { CODE_ONLY_MODEL, GPT_MODEL, GOOGLE_WEB_MODEL, SONNET_MODEL, HAIKU_MODEL, OPUS_MODEL } from '@/constants';
import SearchModal from './SearchModal.vue';

export default {
  name: 'ChatInput',

  props: {
    costEstimation: {
      type: Number,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    inputLocked: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      contextInclude: "manual",
      aiModel: SONNET_MODEL,
      enableFakeModel: false,
      showSearchModal: false,
      GPT_MODEL: GPT_MODEL,
      CODE_ONLY_MODEL: CODE_ONLY_MODEL,
      GOOGLE_WEB_MODEL: GOOGLE_WEB_MODEL,
      HAIKU_MODEL: HAIKU_MODEL,
      SONNET_MODEL: SONNET_MODEL,
      OPUS_MODEL: OPUS_MODEL,
    };
  },

  components: {
    SearchModal,
  },

  watch: {
    contextInclude: function (val) {
      console.log('In input view, total-context-include-changed: ' + val);
      EventBus.emit('total-context-include-changed', val);
    },
  },

  methods: {
    adjustHeight() {
      // Adjust the height of the textarea based on its content
      this.$refs.chatTextArea.style.height = 'auto'; // Must be set to auto to reset scrollHeight
      const h = `calc(${this.$refs.chatTextArea.scrollHeight}px - 1ch)`;
      if (this.$refs.chatTextArea.scrollHeight > 500) {
        this.$refs.chatTextArea.style.height = '500px';
        this.$refs.chatTextArea.style.overflowY = 'auto';
        return;
      }
      this.$refs.chatTextArea.style.height = h;
    },
    onMessageAck() {
      if (this.$refs.chatTextArea) {
        this.$refs.chatTextArea.value = ''; // Clear the textarea
        console.log('input box cleared');
        this.adjustHeight();
      }
    },
    checkForNewLine(event) {
      const inputValue = event.target.value;
      if (inputValue.includes('\n')) {
        this.adjustHeight();
      }
      if (inputValue && inputValue.slice(-1) === ' ') {
        this.$emit('user-prompt-updated', inputValue);
      }
    },
    sendMessage() {
      const prompt = this.$refs.chatTextArea.value;
      this.$emit('user-prompt', {
        prompt: prompt,
        modelVersion: this.aiModel,
      });
    },
    doWebSearch() {
      // Disable our web search function because it's way worse than Perperlexity.ai
      // const prompt = this.$refs.chatTextArea.value;
      // this.$emit('DoWebSearch', prompt);
      window.open('https://www.perplexity.ai/', '_blank');
    },
    clearChatLogs() {
      EventBus.emit('clear-history');
      console.log("Chat logs cleared");
    }
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.enableFakeModel = true;
      this.aiModel = 'fake';
      console.log("Fake model enabled");
    }
    this.adjustHeight(); // Initial adjustment to account for pre-loaded content (if any)

    EventBus.on('include-in-context-checkbox-clicked', () => {
      this.contextInclude = 'manual';
    });
  },
  created() {
    EventBus.on('ReceivedServerResponse', this.onMessageAck);
    console.log("ChatInput created, cost estimation: " + this.costEstimation);
  },
};
</script>